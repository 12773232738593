/* Body */

body {
  background-color: #ececec !important;
}
/* NAVBAR */
.navbar {
  color: white;
  background-color: #480398 !important;
}

.navbar button {
  background-color: #ececec;
  color: #000;
  margin: 0px 10px;
}

.navbar p {
  margin: 0px 10px;
}

#dropdown-basic {
  background-color: #9f71d4;
  color: #ffffff;
  border-color: #480398;
}

#dropdown-basic:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 79, 210, 0.5);
}

.dropdown-item.active,
.dropdown-item:active {
  color: white;
  background-color: #480398 !important;
}

.buttonDisabled {
  background-color: #d8d8d8 !important;
  pointer-events: none;
}

.DateRangePicker__CalendarSelection {
  background-color: #9f71d4 !important;
  border: #9f71d4 !important;
}

.DateRangePicker__Date--is-highlighted {
  border: pink !important;
}

.anychart-credits {
  display: none;
}

.rdt_TableCell:hover {
  background-color: #ececec;
}

.toggle-switch {
  margin: 20px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #480398 !important;
  border-color: black;
}

label {
  user-select: none;
}


.btn-primary {
  background-color: #480398 !important;
  border-color: #480398 !important;
}

:root {
  --primary: #480398;
}

.ReactModal__Content{
  background-color: #fafafa !important;
}

.ReactModal__Content *{
  margin: 10px;
}

.ReactModalPortal {
  z-index: 10000000000000000;
  position: absolute;
}
select, input, button {
  outline: none !important;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.closeButton {
  background: #480398;
  color: white;
  border-radius: 50%;
  padding: 0px 7px;
  border: 1px solid black;
  box-shadow: 1px 1px 3px #140b0b99;
}
.closeButton:hover{
  cursor: pointer;
  background: #680388;
}
.pointer:hover{
  cursor: pointer;
}

.text-button {
  color: #680388;
  font-weight: bold;
  margin: 0px;
  user-select: none;
}

.danger{
  color: darkred;
}
.section {
  display: block;
}

.FilteredMultiSelect select option {
  margin-top: 0px;
  margin-bottom: 0px;
}